<template>
  <div class="coursesPage font-17">
    <Loading :loading="isLoading"></Loading>
    <topNav v-if="menus.find((menu) => menu.name == 'topNav')" />

    <b-row class="justify-content-md-center">
      <b-col v-if="menus.find((menu) => menu.name == 'sideMenu')" lg="2">
        <sideMenu />
      </b-col>
      <b-col lg="10" :class="`px-5 mt-5`">
        <b-row>
          <b-col>
            <p style="font-size: 24px" class="pl-md-0 pl-10">
              <b> {{ title }}</b>
            </p>
          </b-col>
        </b-row>
        <b-row
          cols="1"
          cols-sm="2"
          cols-md="3"
          cols-lg="3"
          cols-xl="4"
          align-v="stretch"
          class="pr-10 pb-10 pl-md-0 pl-10"
        >
          <b-col v-for="creator in allTeachers" :key="creator.id" class="py-1">
            <b-col
              md="12"
              class="pointer card-teacher"
              style="min-height: 280px"
              @click="goToTeacher(creator.id)"
            >
              <b-row>
                <b-col cols="12" class="text-center">
                  <b-avatar
                    :src="creator.profile_image"
                    size="8rem"
                    class="mx-3 my-3"
                  ></b-avatar>
                </b-col>
                <b-col class="py-3">
                  <div style="overflow-x: hidden" class="mb-2 text-center">
                    <span
                      style="overflow-x: hidden"
                      class="font-weight-bold text-center"
                    >
                      {{ creator.first_name + " " + creator.last_name }}
                    </span>
                  </div>
                  <div class="text-center">
                    <span style="overflow-x: hidden; font-size: 14px">
                      {{ creator.teacher_position }}
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
// import sideMenu from "@/components/sideMenu.vue";
import { mapState } from "vuex";
import sideMenu from "@/components/sideMenu.vue";
import Loading from "@/components/Loading.vue";

export default {
  name: "coursesPage",
  components: {
    topNav,
    // sideMenuMobile,
    sideMenu,
    Loading,
  },
  computed: {
    ...mapState({
      menus: (state) => {
        return state.Courses.menus;
      },userData: (state) => state.Auth.userData,
      course: (state) => state.Courses.course,
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.getAllTeacherData();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: false,
      allTeachers: [],
      title: "ผู้สอนทั้งหมด",
    };
  },
  methods: {
    async getAllTeacherData() {
      this.allTeachers = await this.$store.dispatch(
        "Teachers/getAllTeacherData"
      );
    },
    async goToTeacher(id) {
      let path = `/teacher-detail`;
      await this.$router.push({
        path: path,
        query: { id: id },
      });
    },
  },
};
</script>
<style>
.coursesPage {
  height: 100%;
  min-height: 100vh;
}

@media (max-width: 767px) {
  .container {
    padding: 0px;
  }
}
</style>
