<template>
  <div class="coursesPage font-17">
    <Loading :loading="isLoading"></Loading>
    <topNav v-if="menus.find((menu) => menu.name == 'topNav')" />

    <b-row class="justify-content-md-center">
      <b-col v-if="menus.find((menu) => menu.name == 'sideMenu')" lg="2">
        <sideMenu />
      </b-col>
      <b-col lg="10" :class="`px-5 mt-5`">
        <b-row>
          <b-col>
            <p style="font-size: 24px" class="pl-md-0 pl-10">
              <b> {{ title }}</b>
            </p>
          </b-col>
      </b-row>
        <b-row
          cols="1"
          cols-sm="2"
          cols-md="3"
          cols-lg="3"
          cols-xl="4"
          align-v="stretch"
          class="pr-10 pb-10 pl-md-0 pl-10"
        >
          <b-col
            v-for="item in allCourses"
            :key="item.id"
            class="subject-card my-2"
            style="max-height: 400px"
          >
          <CourseCard
            v-if="item.subject_payments_pending && item.subject_payments_pending.length > 0"
            :id="item.id"
            :header="item.name"
            :content="item.description"
            :image="item.banner_image ? item.banner_image : noImage"
            :paid_type="item.paid_type"
            :price="item.price"
            :status="item.subject_payments_pending[0].status"
            :enroll="item.enroll"
            @open-modal="openModal" 
          />
          <CourseCard
            v-else
            :id="item.id"
            :header="item.name"
            :content="item.description"
            :image="item.banner_image ? item.banner_image : noImage"
            :paid_type="item.paid_type"
            :price="item.price"
            :enroll="item.enroll"
            @open-modal="openModal" 
          />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal content-class="qr-modal-size"   centered id="qr-modal" hide-header hide-footer >
      <!-- <pre>{{ course.enroll }}</pre> -->
      <div class="text-center pa-5">
        <div v-if="noEnroll">
              คอร์สนี้ยังไม่เปิดรับสมัคร
        <b-button 
        variant="link" 
        class="ml-2 p-0 close" 
        @click="closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </b-button>
        </div>
        <div v-else-if="isEnroll">
        <h2>สแกน QR Code</h2>
        <p>สแกน QR Code เพื่อชำระเงิน</p>
        <b-img src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg" width="200" height="200" fluid alt="QR Code"></b-img>
        <div class="mt-3">
          <h5 class="d-flex justify-content-left">Name</h5>
          <div class="d-flex justify-content-between">
            <p class="text-truncate w-75 text-left">{{course.name}}</p>
            <p>{{course.price}} ฿</p>
          </div>
        </div>
      <div class="d-flex flex-column justify-content-center">
        <b-button variant="secondary" @click="navigateToConfirm"  class="w-100 m-1 z-3">สแกนเสร็จสิ้น</b-button>
        <b-button @click="closeModal" style="background-color: white;color: gray;" class="w-100 m-1 z-3">ยกเลิก</b-button>
      </div>    
  </div>
    <div v-else>
        คอร์สนี้ปิดรับสมัครแล้ว
        <b-button 
        variant="link" 
        class="ml-2 p-0 close" 
        @click="closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </b-button>
    </div>
    </div>
    </b-modal>
  </div>
</template>

<script>

// @ is an alias to /src
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
// import sideMenu from "@/components/sideMenu.vue";
import { mapState } from "vuex";
import sideMenu from "@/components/sideMenu.vue";
import Loading from "@/components/Loading.vue";
import CourseCard from "@/components/CourseCard.vue";
import moment from "moment";

export default {
  name: "coursesPage",
  components: {
    topNav,
    // sideMenuMobile,
    sideMenu,
    Loading,
    CourseCard,
  },
  computed: {
    ...mapState({
      menus: (state) => {
        return state.Courses.menus;
      },userData: (state) => state.Auth.userData,
      course: (state) => state.Courses.course,
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.getAllCoursesData();
    this.isLoading = false;
  },
  data() {
    return {  
      isLoading: false,
      allCourses: [],
      title: "คอร์สเรียนทั้งหมด",
      course_id: 1,
      isEnroll: false,
      noEnroll: false,
      courseStatus: false,

    };
  },
  methods: {
    async getAllCoursesData() {
      this.allCourses = await this.$store.dispatch("Courses/getAllCoursesData",
         this.userData.id);
    },
    async openModal(id) {
      if (!this.$store.state.Auth.access_token) {
        this.$router.push({
          path: "/login",
          query: {
            redirect: this.$route.fullPath 
          },
        });
      } else {
          this.course_id = id
          await this.getContent(id)
          this.$bvModal.show('qr-modal');
        }
    },
    closeModal() {
      this.$bvModal.hide('qr-modal'); 
  },
  navigateToConfirm(){
    this.$router.push({ 
      name: "confirmPayment" ,
    params: { id: this.course_id }
    });
  },

   checkEnroll(enrolls) {
    this.isLoading = true;

        if (!Array.isArray(enrolls) || enrolls.length === 0) {
          this.noEnroll = true;
          return false;
        }else {
          this.noEnroll = false;
        const enrollsActive = enrolls.filter((enroll) => enroll.status);
        console.log(enrollsActive.length <= 0)
        if (enrollsActive.length <= 0) {
          return false;
        } else {
          const now = moment();

          enrollsActive.forEach((enroll) => {
            const start_at = moment(enroll.register_start);
            const end_at = moment(enroll.register_end);
            if (now.isBetween(start_at, end_at)) {
              this.isEnroll = true
            }else{
              this.isEnroll = false

            }
          });

        }
      }
      this.isLoading = false;

    },
    
    async getContent(id) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("Courses/getCourse", {
          id: id,
          user_id: this.userData.id,
        });
        console.log(this.course)
        if (this.course){
          await this.checkEnroll(this.course.enroll)
        }else{
          console.log("no course")
          this.noEnroll = true
        }
        
      }
      catch (error) {
        console.log(error);
      }
      this.isLoading = false;

    },
}
};
</script>
<style>
.coursesPage {
  height: 100%;
  min-height: 100vh;
}
.qr-modal-size {
  max-width: 400px; 
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .container {
    padding: 0px;
  }
}
</style>
